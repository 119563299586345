<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card card-body">
            <div class="g">
                اختر الطلاب <input type="text" placeholder="ابحث هنا..." v-model="q" @change="getStudents()" style="border: none; border: 1px solid #999; padding: 5px"> <button class="btn btn-sm"><i class="fa fa-search"></i> بحث</button>
            </div>
            <div class="g">
                <a href="javascript:;" @click="selected.length ? selected = [] : selected=students.map(function(x){return x.number})"><u><i class="fa fa-check-square"></i> اختيار الكل</u></a>
            </div>
            <div style="width: 100%; border: solid 2px #333; padding: 5px; overflow-y: scroll; height: 250px;">
                <div class="form-check" v-for="student in students" :key="student._id">
                <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="selected" :value="student.number">
                    {{ student.name }}
                </label>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <h5 for="">تحضير الفترة من</h5>
                        <input type="date"
                            class="form-control" v-model="from">
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <h5 for="">إلى</h5>
                        <input type="date"
                            class="form-control" v-model="to">
                    </div>
                </div>
            </div>
            <div class="col-12 text-center">
                <br>
                <button class="btn btn-primary" @click="done()">
                    <i class="fa fa-check"></i>
                    تحضير الفترة
                </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            from: new Date().toISOString().split("T")[0],
            to: new Date().toISOString().split("T")[0],
            selected: [],
            students: [],
            q: null
        }
    },
    created(){
        var g = this;
        if(!checkPer("students|reports|attend-before")){
            this.$router.push('/NotPermitted')
            return false;
        }
        g.getStudents();
    },
    methods: {
        getStudents(){
            var g = this;
            $.post(api + '/user/students/list', {
                jwt: this.user.jwt,
                q: this.q,
                page: 1,
                perpage: 10000
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response;
                }
            }).fail(function(){
                alert("حدث خطأ", 200);
            })
        },
        done(){
            var g = this;
            $.post(api + '/user/students/reports/attend-before', {
                jwt: this.user.jwt,
                from: this.from,
                to: this.to,
                students: g.selected
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200);
            })
        }
    }
}
</script>

<style>

</style>